import * as Yup from "yup"


export const loginValidationSchema = Yup.object().shape({
    identifier: Yup.string()
        .required("Email is required"),
    // .email("Please Enter valid email"),
    password: Yup.string().required("Password is required"),
});


export const customerValidationSchema = Yup.object().shape({
    contact_no_1: Yup.string()
        .required("Mobile is required").max(10),
    contact_name_1: Yup.string().required("Name is required"),
});

export const customerAdditionalValidationSchema = Yup.object().shape({
    contact_no_1: Yup.string()
        .required("Mobile is required").max(10),
    contact_name_1: Yup.string().required("Name is required"),
    contact_email_1: Yup.string().email('Please enter valid email'),
});