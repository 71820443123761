import { Box, Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function NotFound() {
    const navigate = useNavigate();
    const isSmallScreen = false;
    return (
        <>
            <Box
                sx={{
                    alignItems: "center",
                    backgroundColor: "background.paper",
                    display: "flex",
                    minHeight: "100%",
                    px: 3,
                    py: "40px",
                    margin: 'auto'
                }}
            >
                <Container maxWidth="lg">
                    <Typography align="center" color="textPrimary" variant={isSmallScreen ? "h4" : "h1"}>
                        The page you are looking for isn’t here
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 4,
                        }}
                    >
                        <Box
                            alt="Under development"
                            component="img"
                            src="https://app.helloretriever.com/static/error/error404_light.svg"
                            sx={{
                                height: "auto",
                                maxWidth: "100%",
                                width: 400,
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 6,
                        }}
                    >
                        <Button variant="contained" onClick={() => navigate("/dashboard")}>
                            Back to Dashboard
                        </Button>
                    </Box>
                </Container>
            </Box>
        </>
    );
}

export default NotFound;
