import { useState } from "react";
import CustomerInvoicesTable from "../../Components/CustomerInvoices/CustomerInvoicesTable";
import CustomerInvoiceDetails from "../../Components/CustomerInvoices/CustomerInvoiceDetails";

const CustomerInvoices = () => {
    const [invoiceDetailId, setInvoiceDetailId] = useState();

    return (
        <>
            {invoiceDetailId ? (
                <CustomerInvoiceDetails
                    invoiceDetailId={invoiceDetailId}
                    setInvoiceDetailId={(e: any) => setInvoiceDetailId(e)}
                />
            ) : (
                <CustomerInvoicesTable
                    invoiceDetailId
                    setInvoiceDetailId={(e: any) => setInvoiceDetailId(e)}
                />
            )
            }
        </>
    );
};

export default CustomerInvoices;
