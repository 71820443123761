import { Box, Modal, Typography, Fade, Table, TableRow, TableCell, Button, TableBody } from "@mui/material";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { modalFeedbackStyle, modalFeedbackSuccess, modalFeedbackFailed, modalFeedbackTable, btnTheme, colorTheme } from "../../Common/Theme";
import { ThemeProvider } from "@emotion/react";
import InvoicePDF from "./InvoicePDF";
import { useRef } from "react";
import ReactToPrint from 'react-to-print';

const FeedbackModal = ({ open, handleClose,
    tempBillNo, customerName, feedbackModalTitle,
    billNumber, paymentMethod, paymentAmt, paymentSuccessfullClose,
    payments, billingSaleSummary, billingSelectedProduct, customerMobile }: any) => {

    const componentRef = useRef<HTMLDivElement>(null);

    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const todayDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    const currTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title" aria-describedby="modal-description"
            >
                <Fade in={open}>
                    <Box sx={modalFeedbackStyle}>
                        <Box sx={{ overflow: 'auto', width: '100%', padding: '40px' }} id="modal-description">
                            {feedbackModalTitle === 'Transaction successfull!' ? (
                                <Box sx={modalFeedbackSuccess}>
                                    <CheckRoundedIcon sx={{ color: '#fff' }}></CheckRoundedIcon>
                                </Box>
                            ) : (
                                <Box sx={modalFeedbackFailed}>
                                    <CloseRoundedIcon sx={{ color: '#fff' }}></CloseRoundedIcon>
                                </Box>
                            )
                            }
                            <Typography variant="h5" component="h5" sx={{
                                color: `${feedbackModalTitle === 'Transaction successfull!' ? '#23A26D' : '#ff0000'}`, fontWeight: '600', margin: '0 0 13px'
                            }} id="modal-title">
                                {feedbackModalTitle}
                            </Typography>
                            <Typography variant="h5" component="h5" sx={{ margin: '0 0 53px' }}>
                                {payments.length === 0 && paymentAmt}
                                {payments.map((payment: any, index: any) => (
                                    <span className="d-block" key={index}>
                                        {payment.payment_mode}:{payment.total_paid_amt}
                                    </span>
                                ))}
                            </Typography>
                            <Typography sx={{ borderTop: '1px solid #ededed', width: '100%', marginBottom: '45px', height: '1px', lineHeight: '1px' }}>&nbsp;</Typography>
                            <ThemeProvider theme={modalFeedbackTable}>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <Typography sx={{ color: '#707070' }}>
                                                    Bill no.
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography>
                                                    {billNumber}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography sx={{ color: '#707070' }}>
                                                    Payment Time
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography>
                                                    {todayDate}, {currTime}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography sx={{ color: '#707070' }}>
                                                    Payment Method
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography>
                                                    {paymentMethod}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </ThemeProvider>
                            <Typography sx={{ borderTop: '1px dashed #ededed', width: '100%', marginBottom: '30px', height: '1px', lineHeight: '1px' }}>&nbsp;</Typography>
                            {feedbackModalTitle === 'Transaction successfull!' ? (
                                <ThemeProvider theme={btnTheme}>
                                    <ReactToPrint
                                        trigger={() => (
                                            <Button
                                                variant="contained"
                                                onClick={paymentSuccessfullClose}>
                                                Print
                                            </Button>
                                        )}
                                        content={() => componentRef.current}
                                    />
                                    <Button sx={{ backgroundColor: colorTheme.palette.primary.dark, marginLeft: "10px" }} variant="contained"
                                        onClick={paymentSuccessfullClose}>
                                        Close
                                    </Button>
                                </ThemeProvider>
                            ) : (
                                <ThemeProvider theme={btnTheme}>
                                    <Button
                                        variant="contained"
                                        onClick={handleClose}>
                                        Retry payment
                                    </Button>
                                    <Button sx={{ backgroundColor: colorTheme.palette.primary.dark, marginLeft: "10px" }} variant="contained"
                                        onClick={handleClose}>
                                        Close
                                    </Button>
                                </ThemeProvider>
                            )}
                        </Box>
                    </Box>
                </Fade>
            </Modal>

            <div id="downloadInvoice" style={{ position: 'absolute', width: '794px', height: '559px', left: '-999%' }}>
                <InvoicePDF
                    billNo={billNumber}
                    billingSaleSummary={billingSaleSummary}
                    billingSelectedProduct={billingSelectedProduct}
                    tempBillNo={tempBillNo}
                    customerNameFormBilling={customerName}
                    customerMobile={customerMobile}
                    ref={componentRef} />
            </div>

        </>
    )
}

export default FeedbackModal;