import { Route, Routes } from 'react-router-dom';
import './App.css';

import { ToastContainer } from 'react-toastify';
import Login from './Pages/Auth/Login';
import Layout from './Layout/Layout';
import Billing from './Pages/Billing/Billing';
import Dashboard from './Pages/Dashboard/Dashboard';
import Invoices from './Pages/CustomerInvoices/CustomerInvoices';
import NotFound from './Pages/NotFound';
// import InvoicePDF from './Components/Billing/InvoicePDF';
import Customers from './Pages/Customers/Customers';
import ExchangeProducts from './Components/ExchangeProducts/ExchangeProducts';
import ReturnProducts from './Components/ReturnProduct/ReturnProduct';

function App() {
  return (
    <>
      <Routes>
        <Route path='/login' element={<Login />}></Route>
        <Route path='' element={<Layout />}>
          <Route path='' element={<Dashboard />}></Route>
          <Route path='/dashboard' element={<Dashboard />}></Route>
          <Route path='/billing' element={<Billing />}></Route>
          <Route path='/invoices' element={<Invoices />}></Route>
          {/* <Route path='/pdf' element={<InvoicePDF />}></Route> */}
          <Route path='/customers' element={<Customers />}></Route>
          <Route path='/exchange' element={<ExchangeProducts />}></Route>
          <Route path='/return' element={<ReturnProducts />}></Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
