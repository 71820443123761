import { ThemeProvider } from '@mui/material/styles';
import { Grid, Button } from "@mui/material";
import storySet from '../../Assets/images/dashboard-story.svg';
import { btnTheme, colorTheme } from "../../Common/Theme";
import { Link } from 'react-router-dom';

function Dashboard() {
    return (
        <>
            <Grid className="flex-1" container>
                <Grid item xs={6} sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
                    <img src={storySet} className="object-contain h-full w-full" alt="Dashboard story set" />
                </Grid>
                <Grid item xs={6} sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                    <Grid container sx={{ justifyContent: "center", alignItems: "center" }} spacing={1} >
                        <Grid item>

                            <ThemeProvider theme={btnTheme}>
                                <Button variant='contained'><Link to='/billing'>Add new invoice</Link></Button>
                            </ThemeProvider>
                        </Grid>
                        <Grid item>
                            <ThemeProvider theme={btnTheme}>
                                <Button variant='contained' sx={{ backgroundColor: colorTheme.palette.logoPink, '&:hover': { backgroundColor: colorTheme.palette.logoPinkHover } }}>
                                    <Link to='/customers'>Add new customer</Link>
                                </Button>
                            </ThemeProvider>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Dashboard;