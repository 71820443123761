import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUpdatedProducts } from "../../Types/types";

interface ProductsState {
    selectedProducts: IUpdatedProducts[];
}

const initialState: ProductsState = {
    selectedProducts: [],
}

const initialStateDetails = initialState;

const exchangeProductSlice = createSlice({
    name: "exchangeProduct",
    initialState: initialStateDetails,
    reducers: {
        addProducts: (state, action: PayloadAction<IUpdatedProducts>) => {
            const product = action.payload;
            product.net_total = product.unit_price;
            product.sub_total = (product.net_total / (100 + product.tax_rate)) * 100;
            product.tax_amt = (product.sub_total * product.tax_rate) / 100;
            state.selectedProducts.unshift(product);
        },
        updateProductQuantity: (state, action: PayloadAction<{ product: IUpdatedProducts; actionType: "increment" | "decrement"; }>) => {
            const product = state.selectedProducts.find((product) => product.barCode === action.payload.product.barCode);
            if (!product) {
                return;
            }
            if (action.payload.actionType === "increment") {
                product.quantity += 1;
                product.sub_total = product.sub_total + product.sub_total;
                product.mrp = product.mrp + product.mrp;
                product.sub_total_disc = product.sub_total_disc + product.unit_price;
                product.tax_amt = (product.sub_total * product.tax_rate) / 100;
                product.net_total = product.sub_total + product.tax_amt;
            }
            if (action.payload.actionType === "decrement" && product.quantity > 1) {
                product.quantity -= 1;
                product.sub_total = product.sub_total - (product.sub_total / 2);
                product.mrp = product.mrp - (product.mrp / 2);
                product.sub_total_disc = product.sub_total_disc - product.unit_price;
                product.tax_amt = (product.sub_total * product.tax_rate) / 100;
                product.net_total = product.sub_total + product.tax_amt;
            }
        },
        removeProduct: (state, action: PayloadAction<{ barCode: string; }>) => {
            state.selectedProducts = state.selectedProducts.filter((product) => product.barCode !== action.payload.barCode);
        },
        resetState: () => initialState,
    },
});

export const exchangeProductsAction = exchangeProductSlice.actions;
export default exchangeProductSlice.reducer;
