import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from '@reduxjs/toolkit/query'
import authReducer from './Slice/auth'
import { authApi } from "./Api/login";
import { productsApi } from "./Api/productApi";
import billingReducer from "./Slice/ProductList";
import exchangeProductReducer from "./Slice/ExchangeProduct";
import { customerApi } from "./Api/customerApi";
import { invoiceApi } from "./Api/invoiceApi";
import { returnApi } from "./Api/returnApi";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        billingsTabs: billingReducer,
        exchangeProducts: exchangeProductReducer,
        [authApi.reducerPath]: authApi.reducer,
        [productsApi.reducerPath]: productsApi.reducer,
        [customerApi.reducerPath]: customerApi.reducer,
        [invoiceApi.reducerPath]: invoiceApi.reducer,
        [returnApi.reducerPath]: returnApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([authApi.middleware, productsApi.middleware, customerApi.middleware, invoiceApi.middleware, returnApi.middleware]),
});

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

