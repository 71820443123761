import React, { useRef } from 'react';
import InvoicePDF from './InvoicePDF';
import ReactToPrint from 'react-to-print';
import { Grid, Button } from '@mui/material';
import { ThemeProvider } from "@emotion/react";
import { btnTheme } from '../../Common/Theme';
import { PrintOutlined, RefreshOutlined } from '@mui/icons-material';

interface PrintButtonsProps {
    paymentSuccessfullClose: () => void;
    customerName: any;
    tempBillNo: any;
    isDisabled: boolean
    billingSaleSummary: any
    billingSelectedProduct: any
    customerMobile: any
    billNumber: any
}

const PrintButtons: React.FC<PrintButtonsProps> = ({ paymentSuccessfullClose,
    billingSaleSummary, customerName, tempBillNo, isDisabled, billNumber, billingSelectedProduct, customerMobile }) => {
    const componentRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <ThemeProvider theme={btnTheme}>
                <Grid container spacing={2} justifyContent='end'>
                    <Grid item>
                        <ReactToPrint
                            trigger={() => (
                                <Button disabled={isDisabled} variant="contained">
                                    <PrintOutlined />&nbsp;Preview & Print
                                </Button>
                            )}
                            content={() => componentRef.current}
                        />
                    </Grid>
                    <Grid item>
                        <Button onClick={paymentSuccessfullClose} variant="secondary">
                            <RefreshOutlined />&nbsp;Reset
                        </Button>
                    </Grid>
                </Grid>
            </ThemeProvider>

            <div id="downloadInvoice" style={{ position: 'absolute', width: '794px', height: '559px', left: '-999%' }}>
            {/* <div id="downloadInvoice" style={{ width: '794px', height: '559px' }}> */}
                <InvoicePDF
                    billingSaleSummary={billingSaleSummary}
                    billingSelectedProduct={billingSelectedProduct}
                    tempBillNo={tempBillNo}
                    customerMobile={customerMobile}
                    customerNameFormBilling={customerName}
                    ref={componentRef}
                    billNo={billNumber}
                />
            </div>
        </>
    );
}

export default PrintButtons;
