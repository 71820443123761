import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { Grid, Button } from '@mui/material';
import { ThemeProvider } from "@emotion/react";
import { btnTheme } from '../../Common/Theme';
import { PrintOutlined } from '@mui/icons-material';
import { CreditNoteProdDetail } from '../../Shared/Types/creditnote';
import CreditNotPDF from './CreditNotePDF';

interface PrintButtonsProps {
    customerName: string;
    customerContact: string;
    dtSequenceNo: string;
    netTotal: any;
    creditNoteProdDetails: CreditNoteProdDetail[];
    disabled: any
}

const PrintButton: React.FC<PrintButtonsProps> = ({ customerName, customerContact, dtSequenceNo, netTotal, creditNoteProdDetails, disabled }) => {
    const componentRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <ThemeProvider theme={btnTheme}>
                <Grid item>
                    <ReactToPrint
                        trigger={() => (
                            <Button disabled={disabled} variant="contained">
                                <PrintOutlined />&nbsp;Preview & Print
                            </Button>
                        )}
                        content={() => componentRef.current}
                    />
                </Grid>
            </ThemeProvider>

            <div id="downloadInvoice" style={{ position: 'absolute', width: '794px', height: '559px', left: '-999%' }}>
                <CreditNotPDF
                    creditNoteProdDetails={creditNoteProdDetails}
                    customerContact={customerContact}
                    customerName={customerName}
                    netTotal={netTotal}
                    ref={componentRef}
                    dtSequenceNo={dtSequenceNo}
                />
            </div>
        </>
    );
}

export default PrintButton;
