import { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom'
import TopHeader from '../Common/TopHeader';
import { useMatch } from 'react-router-dom';

function Layout() {
    const navigate = useNavigate()

    const { token } = useSelector((state: any) => state.auth);
    const isDashboard = useMatch('dashboard');

    useEffect(() => {
        if (!token) {
            navigate("/login")
        }
    }, [token, navigate])

    return (
        <div>
            {token ?
                <>
                    <div className="flex flex-col h-screen overflow-hidden">
                        <TopHeader />
                        <div className={`flex flex-row flex-1 ${isDashboard ? 'overflow-hidden' : 'overflow-auto'}`}>
                            <Outlet />
                        </div>
                    </div>
                </> : null}
        </div>
    )
}

export default Layout
