import { PayloadAction, createSlice, nanoid } from "@reduxjs/toolkit";
import { IBillingTab, IUpdatedProducts } from "../../Types/types";

const calculateSaleSummary = (products: IUpdatedProducts[]) => {
    const summary = {
        subTotal: 0,
        totalTax: 0,
        total_items: 0,
        netTotal: 0,
        subTotalAmtDisc: 0,
        originalNetTotal: 0,
        discountValue: 0,
        MRP: 0,
        MRPDiscValue: 0,
    };
    products.forEach((product) => {
        summary.subTotal += product.net_total;
        summary.totalTax += product.tax_amt;
        summary.netTotal += product.net_total;
        summary.total_items += product.quantity;
        summary.subTotalAmtDisc += product.sub_total;
        summary.originalNetTotal += product.sub_total_disc;
        summary.MRP += product.mrp
        summary.MRPDiscValue += product.mrp === 0 ? product.unit_price * product.quantity - product.net_total : (product.mrp * product.quantity) - product.net_total;
        summary.discountValue += (product.unit_price * product.quantity) - product.net_total;
    });
    return summary;
};

const BillingTabData = () => {
    const BillingTabDetails = JSON.parse(localStorage.getItem("BillingTabState") || '[]') as IBillingTab[];
    let intiState: IBillingTab[] = [{
        id: nanoid(10),
        billingDetail: {
            selectedProducts: [],
            saleSummary: {
                subTotal: 0,
                subTotalAmtDisc: 0,
                discount: 0,
                totalTax: 0,
                total_items: 0,
                netTotal: 0,
                originalNetTotal: 0,
                type: "percent",
                discountValue: 0,
                MRP: 0,
                MRPDiscValue: 0,
            },
            productDiscounts: {},
            productUnitPrice: {},
            originalUnitPrice: {},
            discount: {
                discount: 0,
                type: 'percent'
            },
            customerDetails: {
                billNo: '',
                contact_name_1: '',
                contact_no_1: ''
            }
        }
    }];
    if (BillingTabDetails.length > 0) {
        intiState = BillingTabDetails;
    }
    return intiState;
}

const initialState: IBillingTab[] = [{
    id: nanoid(10),
    billingDetail: {
        selectedProducts: [],
        saleSummary: {
            subTotal: 0,
            subTotalAmtDisc: 0,
            discount: 0,
            totalTax: 0,
            total_items: 0,
            netTotal: 0,
            originalNetTotal: 0,
            type: "percent",
            discountValue: 0,
            MRP: 0,
            MRPDiscValue: 0,
        },
        productDiscounts: {},
        productUnitPrice: {},
        originalUnitPrice: {},
        discount: {
            discount: 0,
            type: 'percent'
        },
        customerDetails: {
            billNo: '',
            contact_name_1: '',
            contact_no_1: ''
        }
    }
}];

type TAddProduct = {
    tabIndex: number,
    product: IUpdatedProducts
}

type TProductQuantityUpdate = {
    tabIndex: number,
    product: IUpdatedProducts,
    actionType: "increment" | "decrement"
}

const initialStateDetails = BillingTabData()

const billingsTabSlice = createSlice({
    name: 'billingsTab',
    initialState: initialStateDetails,
    reducers: {
        addBillingTab: (state) => {
            const value: IBillingTab = {
                id: nanoid(10),
                billingDetail: { ...initialState[0].billingDetail },
            }
            state.push(value);
        },
        removeBillingTab: (state, action: PayloadAction<{ index: number }>) => {
            const newState = state.filter((_, index) => index !== action.payload.index);
            localStorage.setItem('BillingTabState', JSON.stringify(newState));

            if (newState.length === 0) {
                return [
                    {
                        id: nanoid(10),
                        billingDetail: { ...initialState[0].billingDetail },
                    }
                ];
            }

            return newState;

        },
        addCustomerToBilling: (state, action: PayloadAction<{ tabIndex: any, customerName: any; customerPhone: any, billNo: any }>) => {
            const { tabIndex, customerName, customerPhone, billNo } = action.payload;
            const billingTab = state[tabIndex];
            if (!billingTab) {
                return;
            }
            const { billingDetail } = billingTab;
            billingDetail.customerDetails.contact_name_1 = customerName;
            billingDetail.customerDetails.contact_no_1 = customerPhone;
            billingDetail.customerDetails.billNo = billNo;
            localStorage.setItem('BillingTabState', JSON.stringify(state));
        },
        addProductsToBilling: (state, action: PayloadAction<TAddProduct>) => {
            const { tabIndex, product } = action.payload
            const billingTab = state[tabIndex];
            if (!billingTab) return;
            const { billingDetail } = billingTab;

            const existingProduct = billingDetail.selectedProducts.find(p => p.barCode === product.barCode);

            if (existingProduct) {
                existingProduct.quantity += 1;
                existingProduct.sub_total = (existingProduct.unit_price * existingProduct.quantity) / (1 + existingProduct.tax_rate / 100);
                existingProduct.sub_total_disc = existingProduct.sub_total_disc + existingProduct.unit_price;
                existingProduct.tax_amt = (existingProduct.sub_total * existingProduct.tax_rate) / 100;
                existingProduct.net_total = existingProduct.sub_total + existingProduct.tax_amt;
            } else {
                product.net_total = product.unit_price;
                product.sub_total = (product.net_total / (100 + product.tax_rate)) * 100;
                product.tax_amt = (product.sub_total * product.tax_rate) / 100;
                billingDetail.selectedProducts.unshift(product);
                billingDetail.originalUnitPrice[product.barCode] = { unti_price: product.unit_price };
            }

            const summaryData = calculateSaleSummary(billingDetail.selectedProducts);
            billingDetail.saleSummary.subTotal = summaryData.subTotal;
            billingDetail.saleSummary.subTotalAmtDisc = summaryData.subTotalAmtDisc;
            billingDetail.saleSummary.totalTax = summaryData.totalTax;
            billingDetail.saleSummary.netTotal = summaryData.netTotal;
            billingDetail.saleSummary.total_items = summaryData.total_items;
            billingDetail.saleSummary.originalNetTotal = summaryData.originalNetTotal;
            billingDetail.saleSummary.MRP = summaryData.MRP;
            billingDetail.saleSummary.MRPDiscValue = summaryData.MRPDiscValue;
            billingDetail.saleSummary.discountValue = summaryData.discountValue;
            localStorage.setItem('BillingTabState', JSON.stringify(state));
        },
        updateProductQuantityToBilling: (state, action: PayloadAction<TProductQuantityUpdate>) => {

            const { tabIndex, product: selectedProduct } = action.payload
            const billingTab = state[tabIndex];
            if (!billingTab) return;

            const { billingDetail } = billingTab
            const product = billingDetail.selectedProducts.find((product) => product.barCode === selectedProduct.barCode);
            if (!product) {
                return;
            }
            const originalQuantity = product.quantity;
            if (action.payload.actionType === "increment") {
                product.quantity += 1;
                product.sub_total_disc = product.sub_total_disc + product.unit_price;
            } else if (action.payload.actionType === "decrement" && product.quantity > 1) {
                product.quantity -= 1;
                product.sub_total_disc = product.sub_total_disc - product.unit_price;
            }

            if (product.quantity !== originalQuantity) {
                product.sub_total = (product.unit_price * product.quantity) / (1 + product.tax_rate / 100);
                product.tax_amt = (product.sub_total * product.tax_rate) / 100;
                product.net_total = product.sub_total + product.tax_amt;
            }

            const summaryData = calculateSaleSummary(billingDetail.selectedProducts);
            billingDetail.saleSummary.subTotal = summaryData.subTotal;
            billingDetail.saleSummary.subTotalAmtDisc = summaryData.subTotalAmtDisc;
            billingDetail.saleSummary.totalTax = summaryData.totalTax;
            billingDetail.saleSummary.netTotal = summaryData.netTotal;
            billingDetail.saleSummary.total_items = summaryData.total_items;
            billingDetail.saleSummary.originalNetTotal = summaryData.originalNetTotal;
            billingDetail.saleSummary.MRP = summaryData.MRP;
            billingDetail.saleSummary.MRPDiscValue = summaryData.MRPDiscValue;
            billingDetail.saleSummary.discountValue = summaryData.discountValue;
            localStorage.setItem('BillingTabState', JSON.stringify(state));
        },
        updateUnitPriceToBilling: (state, action: PayloadAction<{ productData: any; unit_price: number, tabIndex: number }>) => {
            const { productData, unit_price, tabIndex } = action.payload;
            const billingTab = state[tabIndex];
            if (!billingTab) return;

            const { billingDetail } = billingTab

            billingDetail.productUnitPrice[productData.barCode] = { unti_price: unit_price };
            billingDetail.selectedProducts.forEach((product) => {
                if (product.prodBarCode === productData.prodBarCode) {
                    product.unit_price = unit_price;
                    product.quantity = 1;
                    product.sub_total_disc = product.unit_price * product.quantity;
                    product.net_total = product.unit_price * product.quantity;
                    product.sub_total = (product.net_total / (100 + product.tax_rate)) * 100;
                    product.tax_amt = (product.sub_total * product.tax_rate) / 100;
                }
            });
            const summaryData = calculateSaleSummary(billingDetail.selectedProducts);
            billingDetail.saleSummary.subTotal = summaryData.subTotal;
            billingDetail.saleSummary.subTotalAmtDisc = summaryData.subTotalAmtDisc;
            billingDetail.saleSummary.totalTax = summaryData.totalTax;
            billingDetail.saleSummary.netTotal = summaryData.netTotal;
            billingDetail.saleSummary.total_items = summaryData.total_items;
            billingDetail.saleSummary.MRP = summaryData.MRP;
            billingDetail.saleSummary.MRPDiscValue = summaryData.MRPDiscValue;
            billingDetail.saleSummary.originalNetTotal = summaryData.originalNetTotal;
            billingDetail.saleSummary.discountValue = summaryData.discountValue;
            localStorage.setItem('BillingTabState', JSON.stringify(state));
        },
        updateProductTaxDetailsToBilling: (state, action: PayloadAction<{ barCode: string; newTaxDetails: any, tabIndex: number }>) => {
            const { barCode, newTaxDetails, tabIndex } = action.payload;
            const billingTab = state[tabIndex];
            if (!billingTab) return;

            const { billingDetail } = billingTab

            const product = billingDetail.selectedProducts.find((product) => product.barCode === barCode);
            if (product) {
                product.tax_rate = newTaxDetails.tax_rate;
                product.net_total = product.unit_price;
                product.sub_total = (product.net_total / (100 + product.tax_rate)) * 100;
                product.tax_amt = (product.sub_total * product.tax_rate) / 100;
            }
            const summaryData = calculateSaleSummary(billingDetail.selectedProducts);
            billingDetail.saleSummary.subTotal = summaryData.subTotal;
            billingDetail.saleSummary.subTotalAmtDisc = summaryData.subTotalAmtDisc;
            billingDetail.saleSummary.totalTax = summaryData.totalTax;
            billingDetail.saleSummary.netTotal = summaryData.netTotal;
            billingDetail.saleSummary.total_items = summaryData.total_items;
            billingDetail.saleSummary.MRP = summaryData.MRP;
            billingDetail.saleSummary.MRPDiscValue = summaryData.MRPDiscValue;
            billingDetail.saleSummary.originalNetTotal = summaryData.originalNetTotal;
            billingDetail.saleSummary.discountValue = summaryData.discountValue;
            localStorage.setItem('BillingTabState', JSON.stringify(state));
        },
        onUpdatediscountPriceToBilling: (state, action: PayloadAction<{ discount: number; type: string; tabIndex: number }>) => {
            const { tabIndex } = action.payload;
            const billingTab = state[tabIndex];
            if (!billingTab) return;

            const { billingDetail } = billingTab;

            billingDetail.saleSummary.discount = action.payload.discount;
            billingDetail.saleSummary.type = action.payload.type;
            billingDetail.selectedProducts.forEach((product, index) => {
                const originalUnitPrice = billingDetail.originalUnitPrice[product.barCode];
                const discount = billingDetail.productDiscounts[product.barCode];
                if (originalUnitPrice && product.unit_price === originalUnitPrice.unti_price || !discount) {
                    product.tax_amt = (product.sub_total * product.tax_rate) / 100;
                    if (action.payload.type === "percent") {
                        const discountCalculation = 1 - (action.payload.discount / 100);
                        product.sub_total *= discountCalculation;
                        product.tax_amt = (product.sub_total * product.tax_rate) / 100;
                        product.net_total = product.sub_total + product.tax_amt;
                        product.sub_total = (product.unit_price * product.quantity) / (1 + product.tax_rate / 100);
                    } else if (action.payload.type === 'amount' && index === 0) {
                        product.sub_total -= action.payload.discount;
                        product.net_total = product.sub_total + product.tax_amt;
                        product.sub_total = (product.unit_price * product.quantity) / (1 + product.tax_rate / 100);
                    }
                }
            });
            billingDetail.discount.discount = action.payload.discount;
            billingDetail.discount.type = action.payload.type;
            const summaryData = calculateSaleSummary(billingDetail.selectedProducts);
            billingDetail.saleSummary.subTotal = summaryData.subTotal;
            billingDetail.saleSummary.subTotalAmtDisc = summaryData.subTotalAmtDisc;
            billingDetail.saleSummary.totalTax = summaryData.totalTax;
            billingDetail.saleSummary.netTotal = summaryData.netTotal;
            billingDetail.saleSummary.total_items = summaryData.total_items;
            billingDetail.saleSummary.MRP = summaryData.MRP;
            billingDetail.saleSummary.MRPDiscValue = summaryData.MRPDiscValue;
            billingDetail.saleSummary.originalNetTotal = summaryData.originalNetTotal;
            billingDetail.saleSummary.discountValue = summaryData.discountValue;
            localStorage.setItem('BillingTabState', JSON.stringify(state));
        },
        onUpdateProductDiscountPriceToBilling: (state, action: PayloadAction<{ barCode: any; disc_val: any; disc_type: any; tabIndex: number }>) => {
            const { tabIndex } = action.payload;
            const billingTab = state[tabIndex];
            if (!billingTab) return;
            const { billingDetail } = billingTab

            billingDetail.productDiscounts[action.payload.barCode] = { disc_val: action.payload.disc_val, disc_type: action.payload.disc_type };
            const product = billingDetail.selectedProducts.find((p) => p.barCode === action.payload.barCode);
            if (!product) {
                return;
            }
            const discount = billingDetail.productDiscounts[product.barCode];
            const disc_val = discount ? discount.disc_val : 0;
            const disc_type = discount ? discount.disc_type : 'percent';
            product.sub_total = ((product.unit_price * product.quantity) / (100 + product.tax_rate)) * 100;
            if (disc_val === 0) {
                delete billingDetail.productDiscounts[action.payload.barCode];
                if (billingDetail.selectedProducts[action.payload.barCode] && 'disc_val' in billingDetail.selectedProducts[action.payload.barCode]) {
                    const { disc_val, ...rest } = billingDetail.selectedProducts[action.payload.barCode];
                    console.log("🚀 ~ disc_val:", disc_val)
                    billingDetail.selectedProducts[action.payload.barCode] = { ...rest };
                }
                localStorage.setItem('BillingTabState', JSON.stringify(state));

            } else {
                billingDetail.productDiscounts[action.payload.barCode] = { disc_val, disc_type };
            }
            const originalUnitPrice = billingDetail.originalUnitPrice[action.payload.barCode];
            if (originalUnitPrice && product.unit_price === originalUnitPrice.unti_price) {
                if (disc_type === 'percent') {
                    const discountCalculation = 1 - (disc_val / 100);
                    product.sub_total *= discountCalculation;
                    product.tax_amt = (product.sub_total * product.tax_rate) / 100;
                    product.net_total = product.sub_total + product.tax_amt;
                    product.disc_type = disc_type;
                    product.disc_val = disc_val;
                } else if (disc_type === 'amount') {
                    product.sub_total = (product.unit_price * product.quantity) - disc_val;
                    product.tax_amt = (product.sub_total * product.tax_rate) / 100;
                    product.net_total = product.sub_total;
                    product.disc_type = disc_type;
                    product.disc_val = disc_val;
                }
                // product.tax_amt = (product.sub_total * product.tax_rate) / 100;
                // product.net_total = product.sub_total + product.tax_amt;
                // product.disc_type = disc_type;
                // product.disc_val = disc_val;
            }
            const summaryData = calculateSaleSummary(billingDetail.selectedProducts);
            billingDetail.saleSummary.subTotal = summaryData.subTotal;
            billingDetail.saleSummary.subTotalAmtDisc = summaryData.subTotalAmtDisc;
            billingDetail.saleSummary.totalTax = summaryData.totalTax;
            billingDetail.saleSummary.netTotal = summaryData.netTotal;
            billingDetail.saleSummary.total_items = summaryData.total_items;
            billingDetail.saleSummary.MRP = summaryData.MRP;
            billingDetail.saleSummary.MRPDiscValue = summaryData.MRPDiscValue;
            billingDetail.saleSummary.originalNetTotal = summaryData.originalNetTotal;
            billingDetail.saleSummary.discountValue = summaryData.discountValue;
        },
        setProductsOnInitToBilling: (state, action: PayloadAction<{ product: IUpdatedProducts[]; tabIndex: number }>) => {
            const { tabIndex, product } = action.payload
            const billingTab = state[tabIndex];
            if (!billingTab) return;

            const { billingDetail } = billingTab
            billingDetail.selectedProducts = product;
            const summaryData = calculateSaleSummary(billingDetail.selectedProducts);
            billingDetail.saleSummary.subTotal = summaryData.subTotal;
            billingDetail.saleSummary.subTotalAmtDisc = summaryData.subTotalAmtDisc;
            billingDetail.saleSummary.totalTax = summaryData.totalTax;
            billingDetail.saleSummary.netTotal = summaryData.netTotal;
            billingDetail.saleSummary.total_items = summaryData.total_items;
            billingDetail.saleSummary.MRP = summaryData.MRP;
            billingDetail.saleSummary.MRPDiscValue = summaryData.MRPDiscValue;
            billingDetail.saleSummary.originalNetTotal = summaryData.originalNetTotal;
            billingDetail.saleSummary.discountValue = summaryData.discountValue;
        },
        removeProductToBilling: (state, action: PayloadAction<{ barCode: string; tabIndex: number }>) => {
            const { tabIndex, barCode } = action.payload
            const billingTab = state[tabIndex];
            if (!billingTab) return;

            const { billingDetail } = billingTab
            billingDetail.selectedProducts = billingDetail.selectedProducts.filter((product) => product.barCode !== action.payload.barCode);
            delete billingDetail.productDiscounts[barCode];
            delete billingDetail.productUnitPrice[barCode];
            delete billingDetail.originalUnitPrice[barCode];

            const summaryData = calculateSaleSummary(billingDetail.selectedProducts);
            billingDetail.saleSummary.subTotal = summaryData.subTotal;
            billingDetail.saleSummary.subTotalAmtDisc = summaryData.subTotalAmtDisc;
            billingDetail.saleSummary.totalTax = summaryData.totalTax;
            billingDetail.saleSummary.netTotal = summaryData.netTotal;
            billingDetail.saleSummary.total_items = summaryData.total_items;
            billingDetail.saleSummary.originalNetTotal = summaryData.originalNetTotal;
            billingDetail.saleSummary.MRP = summaryData.MRP;
            billingDetail.saleSummary.MRPDiscValue = summaryData.MRPDiscValue;
            billingDetail.saleSummary.discountValue = summaryData.discountValue;
            localStorage.setItem('BillingTabState', JSON.stringify(state));
        },
        resetState: () => initialState,
    }
});

export const billingsAction = billingsTabSlice.actions;
export default billingsTabSlice.reducer;