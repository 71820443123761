import { createApi } from "@reduxjs/toolkit/query/react";
import { baseApiQuery } from "../Interceptor/interceptor";


export const returnApi = createApi({
    reducerPath: "returnApi",
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getReturnInvoice: builder.query<any, { search: any }>({
            query: ({ search }) => ({
                url: `return/invoice/list/?search=${search}`,
                method: "GET",
            }),
        }),
        returnInvoice: builder.mutation<any, any>({
            query: (invoice) => ({
                url: "return/order/",
                method: "POST",
                body: invoice,
            })
        }),

        // Exchange Api
        exchangeValidate: builder.mutation<any, any>({
            query: (invoice) => ({
                url: "exchange/order/validation/",
                method: "POST",
                body: invoice,
            })
        }),
        exchangeInvoice: builder.mutation<any, any>({
            query: (invoice) => ({
                url: "exchange/order/",
                method: "POST",
                body: invoice,
            })
        }),

    })
})

export const { useLazyGetReturnInvoiceQuery, useReturnInvoiceMutation, useExchangeValidateMutation, useExchangeInvoiceMutation } = returnApi