import { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { FormControlLabel, Grid } from '@mui/material';
import { Checkbox } from '@mui/material';
import { colorTheme, modalBoxStyle } from '../../Common/Theme';



const PaymentModal = ({ open, handleClose, modalTitle,
    saleSummary, paymentAmtBtn,
    paymnetHandler, transactionCodeHandler,
    createInvoiceHandler, handleSetPayment, halfPaymentHandle, exchangeProducts }: any) => {

    const [typeCash, setTypeCash] = useState(false);
    const [typeCard, setTypeCard] = useState(false);
    const [typeUPI, setTypeUPI] = useState(false);
    const [halfPayment, sethalfPayment] = useState(false)

    useEffect(() => {
        if (!open) {
            setTypeCash(false);
            setTypeCard(false);
            setTypeUPI(false);
            sethalfPayment(false);
        }
    }, [open]);

    const handleChangeCash = () => {
        setTypeCash(!typeCash)
        handleSetPayment("Cash", !typeCash)
    };
    const handleChangeCard = () => {
        setTypeCard(!typeCard)
        handleSetPayment("Credit Card", !typeCard)
    };
    const handleChangeUPI = () => {
        setTypeUPI(!typeUPI)
        handleSetPayment("UPI", !typeUPI)
    };
    const halfPaymentHandler = () => {
        sethalfPayment(!halfPayment);
        halfPaymentHandle(!halfPayment)
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalBoxStyle}>
                <div className='flex items-center justify-between gap-1'>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
                        Paying through:&nbsp;
                    </Typography>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: colorTheme.palette.primary.main, fontWeight: 'bold', marginRight: 'auto' }}>
                        {modalTitle}
                    </Typography>
                    <Button onClick={handleClose} variant='text' sx={{ fontWeigth: 'bold', fontSize: '25px', color: '#000', padding: '0', minWidth: '15px', background: 'none', height: '15px' }}>
                        <span className='icon-10 bg-closeBlack h-7 w-7 bg-contain'></span>
                    </Button>
                </div>
                <div className="flex flex-col gap-10 my-8 mx-12">
                    <Grid container spacing={1} alignItems='center'>

                        <Grid item xs={4}>
                            <Typography variant="subtitle1" sx={{ color: '#000', whiteSpace: 'nowrap', fontWeight: 700 }}>Total amount</Typography>
                        </Grid>
                        <Grid item xs={1}>:</Grid>
                        <Grid item xs={7}>
                            {!exchangeProducts
                                ? <Typography variant="subtitle1" sx={{ color: '#000', whiteSpace: 'nowrap', fontWeight: 700 }}>
                                    {saleSummary?.netTotal
                                        ? Math.round(parseFloat(saleSummary.netTotal.toString())).toFixed(2)
                                        : 0}
                                </Typography>
                                : <Typography variant="subtitle1" sx={{ color: '#000', whiteSpace: 'nowrap', fontWeight: 700 }}>
                                    {saleSummary ? saleSummary : 'N/A'}
                                </Typography>
                            }
                        </Grid>
                        {modalTitle === 'Multi Modes' && (
                            <>
                                <Grid item xs={4}>
                                    <FormControlLabel control={<Checkbox color="success" onChange={handleChangeCash} />} label="Cash" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel control={<Checkbox color="success" onChange={handleChangeCard} />} label="Card" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel control={<Checkbox color="success" onChange={handleChangeUPI} />} label="UPI" />
                                </Grid>
                                {typeCash && (
                                    <>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle2" sx={{ color: '#000', whiteSpace: 'nowrap' }}>Received amount</Typography>
                                        </Grid>
                                        <Grid item xs={1}>:</Grid>
                                        <Grid item xs={7}>
                                            <TextField onChange={(e) => paymnetHandler(e, "Cash")} hiddenLabel size='small' variant='outlined'></TextField>
                                        </Grid>
                                    </>
                                )}
                                {typeCard && (
                                    <>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle2" sx={{ color: '#000', whiteSpace: 'nowrap' }}>Card amount</Typography>
                                        </Grid>
                                        <Grid item xs={1}>:</Grid>
                                        <Grid item xs={7}>
                                            <TextField onChange={(e) => paymnetHandler(e, 'Credit Card')} hiddenLabel size='small' variant='outlined'></TextField>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle2" sx={{ color: '#000', whiteSpace: 'nowrap' }}>Card code</Typography>
                                        </Grid>
                                        <Grid item xs={1}>:</Grid>
                                        <Grid item xs={7}>
                                            <TextField onChange={transactionCodeHandler} hiddenLabel size='small' variant='outlined'></TextField>
                                        </Grid>
                                    </>
                                )}
                                {typeUPI && (
                                    <>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle2" sx={{ color: '#000', whiteSpace: 'nowrap' }}>UPI amount</Typography>
                                        </Grid>
                                        <Grid item xs={1}>:</Grid>
                                        <Grid item xs={7}>
                                            <TextField onChange={(e) => paymnetHandler(e, 'UPI')} hiddenLabel size='small' variant='outlined'></TextField>
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}
                        {modalTitle !== 'Multi Modes' && (
                            <>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2" sx={{ color: '#000', whiteSpace: 'nowrap' }}>Received Amount</Typography>
                                </Grid>
                                <Grid item xs={1}>:</Grid>
                                <Grid item xs={7}>
                                    <TextField onChange={paymnetHandler} size='small' variant='outlined'></TextField>
                                </Grid>
                            </>
                        )}
                        {modalTitle === 'Card' && (
                            <>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2" sx={{ color: '#000', whiteSpace: 'nowrap' }}>Card code</Typography>
                                </Grid>
                                <Grid item xs={1}>:</Grid>
                                <Grid item xs={7}>
                                    <TextField onChange={transactionCodeHandler} hiddenLabel size='small' variant='outlined'></TextField>
                                </Grid>
                            </>

                        )}
                        <>
                            <Grid item xs={4}>
                                <Typography variant="subtitle2" sx={{ color: '#000', whiteSpace: 'nowrap' }}>Partial payment</Typography>
                            </Grid>
                            <Grid item xs={1}>:</Grid>
                            <Grid item xs={7}>
                                <Checkbox color="success" onChange={halfPaymentHandler} sx={{ padding: 0 }} />
                            </Grid>
                        </>
                        <Grid item xs={12} sx={{
                            borderBottom: '1px solid #000',
                            marginTop: '10px',
                            marginLeft: '-56px',
                            marginRight: '-56px',
                            width: 'calc(100% + 120px)',
                            maxWidth: 'calc(100% + 120px) !important',
                            minWidth: 'calc(100% + 120px) !important',
                            flex: 'none',
                        }}></Grid>
                        <Grid item xs={12} sx={{ marginTop: '30px' }}>
                            <Grid container alignItems='center' justifyContent='center' spacing={2}>
                                <Grid item>
                                    <Button onClick={createInvoiceHandler} disabled={!paymentAmtBtn} type="submit" variant="contained" size='medium'>
                                        Done
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Box>
        </Modal>
    );
}

export default PaymentModal;