import { createApi } from "@reduxjs/toolkit/query/react";
import { baseApiQuery } from "../Interceptor/interceptor";
import { TLogin, TLoginResponse } from "../../Types/types";


export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: baseApiQuery,
    tagTypes: ["posts"],
    endpoints: (builder) => ({
        login: builder.mutation<TLoginResponse, TLogin>({
            query: (user) => ({
                url: 'login/',
                method: 'POST',
                body: user,
            })
        })
    })
})

export const { useLoginMutation } = authApi