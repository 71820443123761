import { Tooltip, Typography } from '@mui/material';
import { Link, useMatch } from 'react-router-dom';
import { PeopleAltOutlined, PointOfSaleOutlined, ReceiptLongOutlined, SettingsOutlined } from '@mui/icons-material';
import { colorTheme, topMenu } from './Theme';
import { ThemeProvider } from '@mui/material';


function MainMenu() {
   return (
      <div className="flex gap-4">
         <ThemeProvider theme={topMenu}>
            <Tooltip title="Billing" placement="top">
               <Link to='/billing' className={`${useMatch('billing') ? 'active border-primaryMain text-primaryMain' : 'border-slate-200'} billing flex items-center justify-between gap-1 p-3 border-b-2 border-solid group hover:border-primaryMain`}>
                  <PointOfSaleOutlined sx={{ color: useMatch('billing') ? colorTheme.palette.primary.main : '#bbb' }} className='group-hover:text-primaryMain' />
                  <Typography sx={{
                     color: useMatch('billing') ? colorTheme.palette.primary.main : colorTheme.palette.topLink,
                     '&:hover': {
                        color: colorTheme.palette.primary.main
                     }
                  }} variant='subtitle2'
                     className="group-hover:text-primaryMain"
                  >Billing</Typography>
               </Link>
            </Tooltip>
            <Tooltip title="Invoices" placement="top">
               <Link to='/invoices' className={`${useMatch('invoices') ? 'active border-indigo500 text-indigo500' : 'border-slate-200'} invoices flex items-center justify-between gap-1 p-3 border-b-2 border-solid group hover:border-indigo500`}>
                  <ReceiptLongOutlined sx={{ color: useMatch('invoices') ? colorTheme.palette.indigo500 : '#bbb' }} className='group-hover:text-indigo500' />
                  <Typography sx={{
                     color: useMatch('invoices') ? colorTheme.palette.indigo500 : colorTheme.palette.topLink,
                     '&:hover': {
                        color: colorTheme.palette.indigo500
                     }
                  }} variant='subtitle2'
                     className="group-hover:text-indigo500"
                  >Invoices</Typography>
               </Link>
            </Tooltip>
            <Tooltip title="Customers" placement="top">
               <Link to='/customers' className={`${useMatch('customers') ? 'active border-logoPink text-logoPink' : 'border-slate-200'} customers flex items-center justify-between gap-1 p-3 border-b-2 border-solid group hover:border-logoPink`}>
                  <PeopleAltOutlined sx={{ color: useMatch('customers') ? colorTheme.palette.logoPink : '#bbb' }} className='group-hover:text-logoPink' />
                  <Typography sx={{
                     color: useMatch('customers') ? colorTheme.palette.logoPink : colorTheme.palette.topLink,
                     '&:hover': {
                        color: colorTheme.palette.logoPink
                     }
                  }} variant='subtitle2'
                     className="group-hover:text-logoPink"
                  >Customers</Typography>
               </Link>
            </Tooltip>
            <Tooltip title="Setting" placement="top">
               <Link to='/settings' className={`${useMatch('settings') ? 'active border-blueGrey text-blueGrey' : 'border-slate-200'} settings flex items-center justify-between gap-1 p-3 border-b-2 border-solid group hover:border-blueGrey`}>
                  <SettingsOutlined sx={{ color: useMatch('settings') ? colorTheme.palette.blueGrey : '#bbb' }} className='group-hover:text-blueGrey' />
                  <Typography sx={{
                     color: useMatch('settings') ? colorTheme.palette.blueGrey : colorTheme.palette.topLink,
                     '&:hover': {
                        color: colorTheme.palette.blueGrey
                     }
                  }} variant='subtitle2'
                     className="group-hover:text-blueGrey"
                  >Settings</Typography>
               </Link>
            </Tooltip>
         </ThemeProvider>
      </div>
   )
}
export default MainMenu;