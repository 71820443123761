import OutlinedInput from '@mui/material/OutlinedInput';
import { ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { Button, Typography } from "@mui/material";
import { PersonAddAlt1Outlined, Search } from "@mui/icons-material";

import { useEffect, useState, useRef } from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useCreateCustomerMutation, useGetCustomerQuery } from '../../Shared/Store/Api/customerApi';
import { customerValidationSchema } from '../../Shared/Validation/validations';
import { btnTheme, modalBoxStyle } from '../../Common/Theme';
import { TCustomer } from '../../Shared/Types/types';
import { Form } from '../../Common/Form';
import DynamicSearchBox from '../../Common/DynamicSearchBox';
import { useDispatch } from 'react-redux';
import { billingsAction } from '../../Shared/Store/Slice/ProductList';

function Customers({ setCustomerDetails, tabIndex }: any) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const dispatch = useDispatch();
    const [searchCustomer, setSearchCustomer] = useState('');
    const [createCustomer] = useCreateCustomerMutation();
    const { data: customer, refetch } = useGetCustomerQuery({ search: searchCustomer });
    const customerList = customer?.results;
    const customerForm = useForm({ mode: 'all', resolver: yupResolver(customerValidationSchema) });

    const customerSearched = useRef<HTMLDivElement>(null);

    const genrateBillNo = () => {
        const today = new Date();
        const year = today.getFullYear().toString().slice(-2);
        const month = today.getMonth() + 1;
        const day = today.getDate();
        const todayDate = `${day < 10 ? + day : day}${month < 10 ? + month : month}${year}`;
        const randomDigit = Math.floor(Math.random() * 10);
        return `${todayDate}${randomDigit}`;
    };

    // create new customer handler

    const createCustomerHandler = async (customer: any) => {
        try {
            await createCustomer(customer).unwrap().then((res) => {
                const customerDetails = res?.customer;
                dispatch(billingsAction.addCustomerToBilling({
                    tabIndex,
                    billNo: genrateBillNo(),
                    customerName: customerDetails.contact_name_1,
                    customerPhone: customerDetails.contact_no_1
                }));
                if (res.existing_customer === false) {
                    toast.success("Customer created successfully!");
                    customerForm.reset({ contact_name_1: "", contact_no_1: "" });
                    handleClose();
                    setCustomerDetails({
                        name: customerDetails.contact_name_1,
                        mobile: customerDetails.contact_no_1,
                        billNumber: genrateBillNo()
                    });
                    genrateBillNo();
                } else {
                    toast.error("Customer already exists.");
                }
            });
        } catch (error) {
            toast.error("Something went wrong!");
            customerForm.reset();
            handleClose();
        }
    };

    // on click list add customer handler

    const handleCustomerClick = (customer: TCustomer) => {
        dispatch(billingsAction.addCustomerToBilling({
            tabIndex,
            billNo: genrateBillNo(),
            customerName: customer.contact_name_1,
            customerPhone: customer.contact_no_1
        }));
        setSearchCustomer('');
        setCustomerDetails({
            name: customer.contact_name_1,
            mobile: customer.contact_no_1,
            billNumber: genrateBillNo()
        });
        genrateBillNo();
    };

    // on search customer handler

    const handleSearchChange = (e: any) => {
        setSearchCustomer(e.target.value);
    };

    useEffect(() => {
        if (searchCustomer) {
            refetch();
        }
    }, [searchCustomer, refetch]);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (customerSearched.current && !customerSearched.current.contains(event.target)) {
                // setSearchCustomer('');
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [customerSearched, setSearchCustomer]);

    return (
        <>
            <Grid container spacing={1} alignItems='flex-start' justifyContent='space-between' flexWrap='nowrap'>
                <Grid item flex='100%'>
                    <DynamicSearchBox>
                        <FormControl variant="outlined" sx={{ borderColor: '#bbb', width: '100%' }} size='small'>
                            <InputLabel htmlFor="outlined-adornment-password">Search customers</InputLabel>
                            <OutlinedInput
                                id="search-customers"
                                type='text'
                                value={searchCustomer}
                                onChange={handleSearchChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Search customers"
                            />
                        </FormControl>
                    </DynamicSearchBox>
                    {searchCustomer && (
                        <div ref={customerSearched} className="search-result bg-white flex relative">
                            {customerList && customerList.length > 0 ? (
                                <ul className="overflow-auto flex-1 absolute top-0 end-0 start-0 h-96 bg-white shadow-2xl z-10">
                                    {customerList.map((customer: any, index: number) => (
                                        <li className="border-b border-slate-200 p-2 flex items-center gap-2 text-black justify-between hover:bg-primary hover:text-white cursor-pointer"
                                            key={index} // Set the key to the index
                                            onClick={() => handleCustomerClick(customer)}>
                                            <span className="flex-1">{customer.contact_name_1}</span>
                                            <sub><strong className="text-uppercase">{customer.contact_no_1}</strong></sub>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <Typography variant="body2" className="p-2">No customers found.</Typography>
                            )}
                        </div>
                    )}
                </Grid>
                <Grid item>
                    <ThemeProvider theme={btnTheme}>
                        <Button variant='contained' sx={{ whiteSpace: 'nowrap' }} onClick={handleOpen}><PersonAddAlt1Outlined sx={{ margin: '0' }} />&nbsp;New customer</Button>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={modalBoxStyle}>
                                <div className='flex items-center justify-between gap-3'>
                                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: '#6083FF', fontWeight: 'bold' }}>
                                        New Customer
                                    </Typography>
                                    <Button onClick={handleClose} variant='text' sx={{ fontWeigth: 'bold', fontSize: '25px', color: '#000', padding: '0', minWidth: '15px', background: 'none', height: '15px' }}>
                                        <span className='icon-10 bg-closeBlack h-7 w-7 bg-contain'></span>
                                    </Button>
                                </div>

                                <Form reactFormContext={customerForm} onSubmit={createCustomerHandler}>
                                    <div className="flex flex-col gap-10 my-8 mx-12">
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Form.Input name="contact_name_1" label="Name" placeholder="John Doe" size='medium' />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Form.Input name="contact_no_1" label="Mobile" placeholder="1234567890" size='medium' />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container alignItems='center' justifyContent='center' spacing={2}>
                                                    <Grid item>
                                                        <Button onClick={handleClose} type="button" className='btn btn-secondary' variant="secondary" size='medium'>
                                                            Skip
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button type="submit" className='btn btn-primary' variant="contained" size='medium'>
                                                            Save
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Form>
                            </Box>
                        </Modal>
                    </ThemeProvider>
                </Grid>
            </Grid>
        </>
    )
}

export default Customers;