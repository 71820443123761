import React, { useState } from 'react';
import { Tabs, Tab, Box, Button, Grid } from '@mui/material';
import ProductsTable from './ProductsTable';
import { useAppDispatch, useAppSelector } from '../../Shared/Types/rtk';
import { billingsAction } from '../../Shared/Store/Slice/ProductList';
import { ThemeProvider } from '@emotion/react';
import { tabs } from '../../Common/Theme';
import { AddOutlined, CloseOutlined } from '@mui/icons-material';

const BillingsTabs = () => {
    const billingsTabs = useAppSelector((state) => state.billingsTabs);
    const dispatch = useAppDispatch();

    const [tabIndex, setTabIndex] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    const addNewTab = () => {
        dispatch(billingsAction.addBillingTab())
        setTabIndex(billingsTabs.length);
    };

    const removeTab = (index: number) => {
        dispatch(billingsAction.removeBillingTab({ index }))
        if (index === tabIndex) {
            if (tabIndex > 0) {
                setTabIndex(tabIndex - 1);
            } else {
                setTabIndex(0);
            }
        } else if (index < tabIndex) {
            setTabIndex(tabIndex - 1);
        }
    };

    return (
        <div>
            <Box sx={{ borderBottom: 1, borderColor: '#efefef' }}>
                <ThemeProvider theme={tabs}>
                    <Tabs value={tabIndex} onChange={handleChange} aria-label="tabs">
                        {billingsTabs.map((tab, index) => (
                            <Tab
                                key={tab.id}
                                label={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        Billing {index + 1}
                                        <CloseOutlined onClick={() => removeTab(index)} sx={{ fontSize: 16, marginLeft: '15px' }} />
                                    </Box>
                                }
                            />
                        ))}
                        <Button onClick={addNewTab} variant='secondary'>
                            <AddOutlined sx={{ fontSize: 16 }} />
                        </Button>
                    </Tabs>
                </ThemeProvider>
            </Box>
            <div>
                {billingsTabs.map((billing, index) => (
                    <div
                        key={billing.id}
                        role="tabpanel"
                        hidden={tabIndex !== index}
                        id={`tabpanel-${billing.id}`}
                        aria-labelledby={`tab-${billing.id}`}
                    >
                        {tabIndex === index &&
                            <Grid container spacing={2} alignItems='start' justifyContent='start' alignContent='baseline' flexDirection='row-reverse'>
                                <ProductsTable tableIndex={tabIndex} billing={billing} />
                            </Grid>
                        }
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BillingsTabs;
