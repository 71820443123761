import { Box, Button, Checkbox, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, ThemeProvider, Typography } from '@mui/material';
import React, { useState } from 'react';
import { btnTheme, cardItem, colorTheme, labelItem } from '../../Common/Theme';
import DynamicSearchBox from '../../Common/DynamicSearchBox';
import { ChevronLeft, Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLazyGetReturnInvoiceQuery, useReturnInvoiceMutation } from '../../Shared/Store/Api/returnApi';
import { CreditNote } from '../../Shared/Types/creditnote';
import PrintButton from './PrintButton';

const ReturnProducts = () => {
    const navigate = useNavigate();
    const backToInvoice = () => {
        navigate("/invoices");
    };

    const [searchInvoice, setSearchInvoice] = useState('');
    const [creditNote, setCreditNote] = useState<CreditNote | null>(null);
    const [getReturnInvoice, { data: invoiceDetails }] = useLazyGetReturnInvoiceQuery();
    const productDetails = invoiceDetails?.results[0]?.invoice_prod_details || [];

    const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
    const [invoiceReturn] = useReturnInvoiceMutation();

    const handleInvoiceSearch = (e: any) => {
        setSearchInvoice(e.target.value);
        getReturnInvoice({ search: e.target.value });
    };

    const handleCheckboxChange = (product: any) => {
        setSelectedProducts(prevSelected => {
            if (prevSelected.some(p => p.prod_sku === product.prod_sku)) {
                return prevSelected.filter(p => p.prod_sku !== product.prod_sku);
            } else {
                return [
                    ...prevSelected,
                    {
                        ...product,
                        quantity: product.quantity || 1,
                        return_unit_price: product.return_unit_price * (product.quantity || 1)
                    }
                ];
            }
        });
    };

    const handleQuantityChange = (product: any, quantity: number) => {
        if (quantity > product.quantity) {
            toast.error(`You cannot select more than ${product.quantity} for this product.`);
            return;
        }

        setSelectedProducts(prevSelected =>
            prevSelected.map(p =>
                p.prod_sku === product.prod_sku
                    ? {
                        ...p,
                        quantity: quantity,
                        return_unit_price: product.return_unit_price * quantity
                    }
                    : p
            )
        );
    };

    const invoiceReturnHandler = async () => {
        const invoiceData = {
            "dt_sequence_no": invoiceDetails?.results[0]?.dt_sequence_no,
            "customer_name": invoiceDetails?.results[0]?.customer_name,
            "customer_contact": invoiceDetails?.results[0]?.customer_contact,
            "product_details": selectedProducts,
        };

        try {
            await invoiceReturn(invoiceData).unwrap().then((res: any) => {
                if (res) {
                    setCreditNote(res);
                }
                toast.success('Invoice return successfully');
            });
        } catch (error: any) {
            toast.error(error?.data?.non_field_errors[0]);
        }
    };

    return (
        <>
            <section id="main" className="flex flex-col overflow-auto flex-1 p-3 gap-3 pb-32">
                <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={12} sx={{ marginTop: 'auto', display: 'flex', justifyContent: 'end' }}>
                        <Button
                            sx={{ fontWeight: "600", color: colorTheme.palette.indigo500, alignItems: 'center', padding: 0, display: 'flex', lineHeight: '25px' }}
                            onClick={backToInvoice}
                        >
                            <ChevronLeft />
                            Back to Invoices
                        </Button>
                    </Grid>

                    <Grid item xs={6} md={8} sx={{ paddingTop: '0px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box sx={{ ...cardItem, overflow: 'visible' }}>
                                    <Grid container spacing={3} alignItems='center' justifyContent='space-between'>
                                        <Grid item xs={12} md={6}>
                                            <DynamicSearchBox>
                                                <FormControl variant="outlined" sx={{ borderColor: '#bbb', width: '100%' }} size='small'>
                                                    <InputLabel htmlFor="outlined-adornment-password">Search invoice</InputLabel>
                                                    <OutlinedInput
                                                        value={searchInvoice}
                                                        onChange={handleInvoiceSearch}
                                                        type='text'
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton>
                                                                    <Search />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        label="Search invoice"
                                                    />
                                                </FormControl>
                                            </DynamicSearchBox>
                                        </Grid>
                                        <PrintButton
                                            customerName={creditNote?.customer_name || ''}
                                            customerContact={creditNote?.customer_contact || ''}
                                            dtSequenceNo={creditNote?.dt_sequence_no || ''}
                                            netTotal={creditNote?.net_total}
                                            creditNoteProdDetails={creditNote?.credit_note_prod_details || []}
                                            disabled={!creditNote}
                                        />
                                        <Grid item xs={12}>
                                            {productDetails.length > 0 ? (
                                                <table className="w-full customTable">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-left"></th>
                                                            <th className="text-left">Barcode</th>
                                                            <th className="text-left">Product</th>
                                                            <th className="text-left">Qty</th>
                                                            <th className="text-left">Unit Price</th>
                                                            <th className="text-left">Subtotal</th>
                                                            <th className="text-left">Return Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {productDetails.map((product: any, index: number) => (
                                                            <tr key={index}>
                                                                <td className='checkBoxWraper'>
                                                                    <Checkbox
                                                                        checked={selectedProducts.some(p => p.prod_sku === product.prod_sku)}
                                                                        onChange={() => handleCheckboxChange(product)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Grid container spacing={0} alignItems='center'>
                                                                        <Grid item xs={12}>
                                                                            {String(product.prodBarCode)}
                                                                        </Grid>
                                                                        <Grid item xs='auto'>
                                                                            <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: '600' }}>
                                                                                SKU:&nbsp;{String(product.prod_sku)}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </td>
                                                                <td>
                                                                    <Grid container spacing={1} alignItems='center'>
                                                                        <Grid item>
                                                                            {String(product.product_title)}
                                                                        </Grid>
                                                                        {product.size && (
                                                                            <Grid item xs='auto'>
                                                                                <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: '600' }}>
                                                                                    Size:&nbsp;{String(product.size)}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}
                                                                        {product.colour && (
                                                                            <Grid item xs='auto'>
                                                                                <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: '600' }}>
                                                                                    Color:&nbsp;{String(product.colour)}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}
                                                                    </Grid>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="number"
                                                                        placeholder="0"
                                                                        value={selectedProducts.find(p => p.prod_sku === product.prod_sku)?.quantity || product.quantity}
                                                                        onChange={(e) => handleQuantityChange(product, Number(e.target.value))}
                                                                        className="discountInp text-center"
                                                                        disabled={!selectedProducts.some(p => p.prod_sku === product.prod_sku)}
                                                                        min={1}
                                                                        max={product.quantity}
                                                                    />
                                                                </td>
                                                                <td>{product.unit_price}</td>
                                                                <td>{product.sub_total}</td>
                                                                <td>
                                                                    {selectedProducts.find(p => p.prod_sku === product.prod_sku)?.return_unit_price || product.return_unit_price}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <Typography sx={{ textAlign: "center" }}>No data found</Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} md={4} sx={{ paddingTop: '0px' }}>
                        <Box sx={{ ...cardItem, overflow: 'visible' }}>
                            <Grid container spacing={1} justifyContent='space-between'>
                                <Grid item xs={12} md={6}>
                                    <Typography variant='subtitle1' sx={{ fontWeight: 600, color: colorTheme.palette.primary.main }}>Invoice Details</Typography>
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ textAlign: "end" }}>
                                    <ThemeProvider theme={btnTheme}>
                                        <Button variant="contained"
                                            onClick={invoiceReturnHandler}
                                            disabled={selectedProducts.length === 0}
                                        >
                                            Return Submit
                                        </Button>
                                    </ThemeProvider>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2" sx={labelItem}>Invoice no.</Typography>
                                </Grid>
                                <Grid item xs={1}>:</Grid>
                                <Grid item xs={7}>{invoiceDetails?.results[0]?.dt_sequence_no}</Grid>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2" sx={labelItem}>Customer name</Typography>
                                </Grid>
                                <Grid item xs={1}>:</Grid>
                                <Grid item xs={7}>{invoiceDetails?.results[0]?.customer_name}</Grid>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2" sx={labelItem}>Customer mobile no.</Typography>
                                </Grid>
                                <Grid item xs={1}>:</Grid>
                                <Grid item xs={7}>{invoiceDetails?.results[0]?.customer_contact}</Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </section>
        </>
    );
}

export default ReturnProducts;
