import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../Store/store';

export type ExtraOptionType = {
  enableLoading?: boolean;
  isAuthorizationApi?: boolean;
  [key: string]: unknown;
};

// src/app/hooks.ts


// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


export type BaseApiQueryType = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, ExtraOptionType>;