import * as React from 'react';
import Brand from "./Brand";
import { IconButton, Link, Menu, MenuItem, Tooltip, ListItemIcon, Divider, Typography, Grid } from "@mui/material";
import { AccessTimeOutlined, CalendarMonthOutlined, Logout, NotificationsOutlined } from '@mui/icons-material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { authAction } from '../Shared/Store/Slice/auth';
import { toast } from 'react-toastify';
import { colorTheme } from './Theme';
import MainMenu from './MainMenu';
import { billingsAction } from '../Shared/Store/Slice/ProductList';


const theme = createTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#6083FF',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
    components: {
        MuiMenu: {
            styleOverrides: {
                list: {
                    padding: '5px',
                    gap: '5px',
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    gap: '5px',
                    borderRadus: '5px',
                    '&:hover': {
                        backgroundColor: colorTheme.palette.primary.mainHover,
                        color: '#fff',
                        '& .MuiSvgIcon-root': {
                            color: '#fff', // Change the color of the icon on hover
                        },
                    }
                }
            }
        }
    }
});

function TopHeader() {
    const logout = useDispatch();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const userName = localStorage.getItem('user');
    const userEmail = localStorage.getItem('email');
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const todayDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    const currTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    const logoutHandler = async () => {
        try {
            await logout(authAction.logout());
            localStorage.removeItem('token')
            localStorage.clear()
            dispatch(billingsAction.resetState());
            toast.success("Logout Successfully!")
        }
        catch (error) {
            toast.error('Something went wrong. Please try again later.')
        }
    }

    return (
        <>
            <div className={`flex items-center justify-between px-3 gap-3 border border-b border-slate-200`} id="header">
                <Brand />
                <MainMenu />
                <div className="flex items-center gap-3 flex-nowrap">
                    <Tooltip title="Date">
                        <Grid container spacing={0} alignItems='center' flexWrap='nowrap'>
                            <Grid item>
                                <CalendarMonthOutlined sx={{ color: colorTheme.palette.blue500 }} />
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2" sx={{ fontWeight: '600', color: '#999', textTransform: 'uppercase' }}>&nbsp;{todayDate}</Typography>
                            </Grid>
                        </Grid>
                    </Tooltip>
                    <Tooltip title="Time">
                        <Grid container spacing={0} alignItems='center'>
                            <Grid item>
                                <AccessTimeOutlined sx={{ color: colorTheme.palette.green500 }} />
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2" sx={{ fontWeight: '600', color: '#999', textTransform: 'uppercase' }}>&nbsp;{currTime}</Typography>
                            </Grid>
                        </Grid>
                    </Tooltip>
                    <Tooltip title="Notifications">
                        <Link href="#">
                            <NotificationsOutlined sx={{ color: colorTheme.palette.deepOrange500 }} />
                        </Link>
                    </Tooltip>
                    <Tooltip title="Account">
                        <IconButton sx={{ padding: 0 }} onClick={handleClick}>
                            <AccountCircleOutlinedIcon sx={{ color: colorTheme.palette.brown500 }} />
                        </IconButton>
                    </Tooltip>
                    <ThemeProvider theme={theme}>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem>
                                <ListItemIcon>
                                    <AccountCircleOutlinedIcon sx={{ color: colorTheme.palette.primary.main }} />
                                </ListItemIcon>
                                <div className="flex flex-col">
                                    <Typography variant="h6" sx={{ margin: 0 }}>
                                        {userName}
                                    </Typography>
                                    {userEmail}
                                </div>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={logoutHandler}>
                                <ListItemIcon>
                                    <Logout sx={{ color: colorTheme.palette.primary.main }} />
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>
                    </ThemeProvider>
                </div>
            </div>
        </>
    )
}
export default TopHeader;