import { ThemeProvider } from '@mui/material/styles';
import { useState } from "react";
import Brand from "../../Common/Brand";
import OnboardingBg from "./OnboardingBg";
import { Form } from "../../Common/Form";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useLoginMutation } from "../../Shared/Store/Api/login";
import { yupResolver } from "@hookform/resolvers/yup";
import { authAction } from "../../Shared/Store/Slice/auth";
import { TLogin } from "../../Shared/Types/types";
import { loginValidationSchema } from "../../Shared/Validation/validations";
import { Button, Grid } from "@mui/material";
import { toast } from "react-toastify";
import { btnTheme } from "../../Common/Theme";

function Login() {

    const [showPassword, setShowPassword] = useState(false);

    function toggleIcon() {
        setShowPassword(!showPassword);
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [login] = useLoginMutation()

    const loginFromValue = useForm({ mode: "all", resolver: yupResolver(loginValidationSchema) });

    // Login Handler

    const loginHandler = async (user: TLogin) => {
        try {
            await login(user).unwrap().then((res) => {
                const token = res.token;
                dispatch(authAction.login({ token: token }));
                localStorage.setItem("token", token)
                localStorage.setItem("user", res.username)
                localStorage.setItem("email", res.email)
                navigate("/dashboard")
                toast.success("Logged in successfully!");
            })
        } catch (error) {
            toast.error("Invalid login credentials!");
        }
    };


    return (
        <>
            <div className="w-screen h-screen overflow-hidden flex">
                <div className='flex flex-row flex-nowrap flex-1'>
                    <OnboardingBg />
                    <div className='flex flex-1 flex-col p-3 w-2/3 overflow-hidden'>
                        <Brand />
                        <div className="card login-card overflow-hidden flex">
                            <Form reactFormContext={loginFromValue} onSubmit={loginHandler}>
                                <div className="flex flex-col gap-10">
                                    <h2 className="font-bold text-2xl mb-3">Login</h2>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Form.Input name="identifier" label="Email id" variant="standard" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="relative">
                                                <Form.Input type={showPassword ? "text" : 'password'} name="password" label="Password" variant="standard" />
                                                {showPassword ? (
                                                    <span className="icon eye-slash absolute right-4 top-4 view-password cursor-pointer" onClick={toggleIcon} title='Hide password'></span>
                                                ) : (
                                                    <span className="icon eye absolute right-4 top-4 view-password cursor-pointer" onClick={toggleIcon} title='Show password'></span>
                                                )
                                                }
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <div className="text-center mt-4">
                                        <ThemeProvider theme={btnTheme}>
                                            <Button type="submit" className='btn btn-primary' variant="contained">
                                                Login
                                            </Button>
                                        </ThemeProvider>
                                    </div>
                                    <div className='flex justify-center'>
                                        <Link to={"/forgotPassword"} className='form-link'>Forgot password?</Link>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;