import sdfLogo from '../../Assets/images/sdf-logo.png';
import shree from '../../Assets/images/shree.png';
import React from 'react';
import '../Billing/PDF.css';

interface InvoicePDFProps {
    customerName: any
    customerContact: any
    dtSequenceNo: any
    netTotal: any
    creditNoteProdDetails: any;
}

const ExchangePDF = React.forwardRef<HTMLDivElement, InvoicePDFProps>(
    ({ customerName, customerContact, dtSequenceNo, netTotal, creditNoteProdDetails }, ref) => {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();
        const todayDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;


        return (
            <>
                <div className="table-outer" ref={ref}>
                    <table className='printPDFTable'>
                        <thead>
                            <tr>
                                <td className="logo" style={{ verticalAlign: 'middle' }}>
                                    <img src={sdfLogo} alt="SDF" width="141" height="45" />
                                    <p style={{ color: '#D13F80', fontFamily: 'Gabriola', fontSize: '15px', fontWeight: '400', whiteSpace: 'nowrap', padding: '5px 5px 0', lineHeight: '14px' }}>
                                        a venture of Gangaram Enterprises
                                    </p>
                                    <p style={{ color: '#53266A', fontFamily: 'Gabriola', fontSize: '15px', fontWeight: '400', whiteSpace: 'nowrap', padding: '5px 5px 0', lineHeight: '14px' }}>
                                        exclusive ladies gowns, kurtis, leggings, palazzos and  more
                                    </p>
                                    <p style={{
                                        color: '#000', fontSize: '12px', fontWeight: '500', whiteSpace: 'nowrap', padding: '5px 5px 0', lineHeight: '14px'
                                    }}>Contact no. : +91 7600070053</p>
                                    <p style={{ verticalAlign: 'bottom', color: '#F00', fontSize: '12px', fontWeight: '700', letterSpacing: '-0.081px', padding: '5px 5px 0' }}>
                                        GSTIN: 24AGBPA8175F1ZQ
                                    </p>
                                </td>
                                <td className="logo" style={{ textAlign: 'center' }}>
                                    <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={shree} alt="SDF" />
                                </td>
                                <td>
                                    <p style={{ fontSize: 13, color: '#000', textTransform: 'uppercase', fontWeight: 500, margin: '10px 0 0' }}>Retail Invoice</p>

                                    <p style={{
                                        color: '#000', fontSize: '12px', fontWeight: '700', verticalAlign: 'middle', margin: '5px 0 0'
                                    }}>Exchange invoice no.: <span style={{ fontWeight: '400' }}>{dtSequenceNo}</span></p>

                                    <p style={{
                                        color: '#000', fontSize: '12px', fontWeight: '700', verticalAlign: 'middle', margin: '5px 0 0'
                                    }}>Date : <span style={{ fontWeight: '400' }}>{todayDate}</span></p>
                                    <p style={{
                                        color: '#000', fontSize: '12px', fontWeight: '700', verticalAlign: 'middle', margin: '5px 0 0'
                                    }}>Name : <span style={{ fontWeight: '400' }}>{customerName}</span></p>
                                    <p style={{
                                        color: '#000', fontSize: '12px', fontWeight: '700', verticalAlign: 'middle', margin: '5px 0 0'
                                    }}>Mobile : <span style={{ fontWeight: '400' }}>{customerContact}</span></p>
                                </td>
                            </tr>
                        </thead>
                        <tbody className='creditNoteTableBody'>
                            <tr>
                                <td colSpan={3}>
                                    <table className='productDetals'>
                                        <thead>
                                            <tr>
                                                <th className='text-left'>Sr. No.</th>
                                                <th className='text-left'>Description</th>
                                                <th className='text-center'>HSN</th>
                                                <th className='text-center'>Qty.</th>
                                                <th className='text-right'>Rate</th>
                                                <th className='text-right'>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {creditNoteProdDetails?.returning_products?.length > 0 ? (
                                                <>
                                                    {creditNoteProdDetails?.returning_products.map((product: any, index: number) => (
                                                        <tr key={index}>
                                                            <td className='text-left'>{index + 1}</td>
                                                            <td>{product.product_title || ''}</td>
                                                            <td className='text-center'>{product.hsn_code || ''}</td>
                                                            <td className='text-center'>{product.quantity || ''}</td>
                                                            <td className='text-right'>{product.sub_total || ''}</td>
                                                            <td className='text-right'>{parseFloat(product.sub_total || 0).toFixed(2)}</td>
                                                        </tr>
                                                    ))}
                                                    {creditNoteProdDetails?.new_products.map((product: any, index: number) => (
                                                        <tr key={index + creditNoteProdDetails.returning_products.length}>
                                                            <td className='text-left'>{index + 1}</td>
                                                            <td>{product.prod_title || ''}</td>
                                                            <td className='text-center'>{product.hsn_code || ''}</td>
                                                            <td className='text-center'>{product.quantity || ''}</td>
                                                            <td className='text-right'>{parseFloat(product.sub_total || 0).toFixed(2)}</td>
                                                            <td className='text-right'>{parseFloat(product.sub_total || 0).toFixed(2)}</td>
                                                        </tr>
                                                    ))}
                                                </>
                                            ) : (
                                                <tr>
                                                    <td colSpan={6} className='text-center'>No products selected</td>
                                                </tr>
                                            )}
                                        </tbody>

                                    </table >
                                </td >
                            </tr >
                        </tbody >
                        <tfoot>
                            <tr>
                                <td colSpan={3}>
                                    <table>
                                        <tr><th colSpan={3} style={{ borderBottom: '1px solid #eee' }}>&nbsp;</th></tr>
                                        <tr className='amount-row'>
                                            <th style={{ textAlign: 'left', backgroundColor: 'transparent', lineHeight: 'normal' }}></th>
                                            <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal', fontSize: '12px' }}>Net amount</th>
                                            <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal', fontSize: '12px' }}>
                                                ₹ {Math.round(parseFloat(netTotal || 0)).toFixed(2)}
                                            </th>
                                        </tr>
                                        <tr><th colSpan={3}>&nbsp;</th></tr>
                                        <tr className='copyright'>
                                            <td>
                                                <p>Terms and Conditions</p>
                                                <ul>
                                                    <li>Subject to Vadodara jurisdiction.</li>
                                                    <li>Goods once sold will not be returned or exchanged.</li>
                                                    <li>Goods sold as is, not guarantee of work, color & material.</li>
                                                    <li>E. & O.E</li>
                                                </ul>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>SB- 28, Siddharth complex, Nr Express Hotel,<br />RC Dutt road, Alkapuri, Vadodara- 390007, Guj.
                                                <p style={{ textWrap: 'nowrap' }}>
                                                    <strong>info@shudhdesifashion.com, www.shudhdesifashion.com</strong>
                                                </p>
                                            </td>
                                            <td style={{ textAlign: 'center', verticalAlign: 'bottom', fontSize: '10px' }}>
                                                <p style={{ borderTop: '1px solid #eee', lineHeight: '5px' }}>&nbsp;</p>
                                                <p style={{ marginBottom: '5px' }}>Signature</p>
                                                <p><strong>For Gangaram Enterprise</strong></p>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </tfoot>
                    </table >
                    <footer></footer>
                    <div className='tableFooter'>
                        <table>
                        </table>
                    </div>
                </div >
            </>
        )
    });
export default ExchangePDF;