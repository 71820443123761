import { createApi } from "@reduxjs/toolkit/query/react";
import { baseApiQuery } from "../Interceptor/interceptor";
import { TInvoice, TInvoiceList } from "../../Types/types";


export const invoiceApi = createApi({
    reducerPath: "invoiceApi",
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        createInvoive: builder.mutation<any, TInvoice>({
            query: (invoice) => ({
                url: "createcustomerinvoice/",
                method: "POST",
                body: invoice,
            })
        }),
        getInvoice: builder.query<TInvoiceList, { search: any, limit: number, offset: number, start_date: string, end_date: string, status: any }>({
            query: ({ search, limit, offset, start_date, end_date, status }) => ({
                url: `getcustomerinvoice/?search=${search}&limit=${limit}&offset=${offset}&start_date=${start_date}&end_date=${end_date}&status=${status}`,
                method: "GET",
            }),
        }),
        getInvoiceDetails: builder.query<any, any>({
            query: (customerId) => ({
                url: `getcustomerinvoice/${customerId}/`,
                method: "GET",
            }),
        }),
    })
})

export const { useCreateInvoiveMutation, useGetInvoiceQuery, useGetInvoiceDetailsQuery } = invoiceApi