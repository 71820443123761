import { useMatch } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { searchBox, colorTheme } from './Theme';

const DynamicSearchBox = ({ children }: any) => {
   const isBilling = useMatch('billing');
   const isInvoices = useMatch('invoices');

   const dynamicTheme = createTheme({
      ...searchBox,
      components: {
         MuiInputLabel: {
            styleOverrides: {
               root: {
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: colorTheme.palette.topLink,
                  '&.Mui-focused': {
                     color: isBilling ? colorTheme.palette.primary.main : isInvoices ? colorTheme.palette.indigo500 : colorTheme.palette.logoPink,
                  },
               },
            },
         },
         MuiOutlinedInput: {
            styleOverrides: {
               root: {
                  '--TextField-brandBorderColor': colorTheme.palette.primary.dark,
                  '--TextField-brandBorderHoverColor': colorTheme.palette.primary.light,
                  '--TextField-brandBorderFocusedColor': isBilling ? colorTheme.palette.primary.main : isInvoices ? colorTheme.palette.indigo500 : colorTheme.palette.logoPink,
                  '& .MuiSvgIcon-root': {
                     color: colorTheme.palette.topLink,
                  },
                  '& label.Mui-focused': {
                     color: isBilling ? colorTheme.palette.primary.main : isInvoices ? colorTheme.palette.indigo500 : colorTheme.palette.logoPink,
                  },
                  '&.MuiOutlinedInput-root': {
                     borderColor: colorTheme.palette.primary.dark,
                     '& input': {
                        fontSize: '14px',
                        lineHeight: '16px',
                        color: colorTheme.palette.topLink,
                     }
                  },
                  '&.Mui-focused': {
                     borderColor: isBilling ? colorTheme.palette.primary.main : isInvoices ? colorTheme.palette.indigo500 : colorTheme.palette.logoPink,
                     '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: isBilling ? colorTheme.palette.primary.main : isInvoices ? colorTheme.palette.indigo500 : colorTheme.palette.logoPink,
                        '& span': {
                           color: isBilling ? colorTheme.palette.primary.main : isInvoices ? colorTheme.palette.indigo500 : colorTheme.palette.logoPink,
                        }
                     },
                     '& .MuiSvgIcon-root': {
                        color: isBilling ? colorTheme.palette.primary.main : isInvoices ? colorTheme.palette.indigo500 : colorTheme.palette.logoPink,
                     }
                  }
               },
            },
         }
      },
   });

   return <ThemeProvider theme={dynamicTheme}>{children}</ThemeProvider>;
};

export default DynamicSearchBox;
