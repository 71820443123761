import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseApiQueryType, ExtraOptionType } from '../../Types/rtk';
import { authAction } from '../Slice/auth';
import { AppConfig } from '../../constants/AppConfig';

export const baseQuery = (options: ExtraOptionType) => {
    return fetchBaseQuery({
        baseUrl: AppConfig.CONFIGURATION.API_URL,
        fetchFn: async (...args) => {
            return fetch(...args);
        },
        prepareHeaders: (header) => {
            const { isAuthorizationApi = true } = options;
            if (!isAuthorizationApi) return header;
            const token = localStorage.getItem('token');
            if (!token) {
                header.set('Authorization', `Api-Key ${AppConfig.CONFIGURATION.APP_KEY}`);
            }
            else {
                header.set('Authorization', `Token ${token}`);
                header.set('Api-Key', `Api-Key ${AppConfig.CONFIGURATION.APP_KEY}`);
            }
            return header;
        },
    }) as BaseApiQueryType;
};

export const baseApiQuery: BaseApiQueryType = async (args, api, extraOption = {}) => {
    const apiCopy = { ...api };
    const { dispatch } = apiCopy;

    try {
        const query = baseQuery(extraOption);
        const result = await query(args, api, extraOption);
        if (result.error) {
            const { error } = result;
            if (error.status === 401) {
                localStorage.getItem('token');
                dispatch(authAction.logout());
                alert('Your session has expired. please log in again.')
            } else if (error.status === 500) {
                alert('Please try after sometimes.')
            }
        }
        return result;
    } catch (error: any) {
        console.log("🚀 ~ constbaseApiQuery:BaseApiQueryType= ~ error:", error)
        throw error;
    }
};
